import React, { Suspense, Fragment, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

// import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Element props={true} />
                  )}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: "true",
    path: "/signin",
    element: lazy(() => import("./pages/preSignup/signin/signin")),
  },
  {
    exact: "true",
    path: "/",
    element: lazy(() => import("./pages/preSignup/home/home")),
  },
  {
    exact: "true",
    path: "/signup",
    element: lazy(() => import("./pages/preSignup/signup")),
  },
  {
    exact: "true",
    path: "/forgetPassword",
    element: lazy(() => import("./pages/preSignup/forgotPassword")),
  },
  // {
  //   exact: "true",
  //   path: "/auth/reset-password-1",
  //   element: lazy(() => import("./views/auth/reset-password/ResetPassword1")),
  // },
  {
    path: "*",
    layout: AdminLayout,
    routes: [
      {
        exact: "true",
        path: "/user-dashboard",
        element: lazy(() => import("./views/dashboard")),
      },
      {
        exact: "true",
        path: "/eating-out",
        element: lazy(() => import("./views/eatingout/Eatingout")),
      },
      {
        exact: "true",
        path: "/",
        element: lazy(() => import("./pages/preSignup/home/home")),
      },
      {
        exact: "true",
        path: "/breakfast",
        element: lazy(() => import("./pages/postSignup/meals/breakfast")),
      },
      {
        exact: "true",
        path: "/lunch",
        element: lazy(() => import("./pages/postSignup/meals/lunch")),
      },
      {
        exact: "true",
        path: "/dinner",
        element: lazy(() => import("./pages/postSignup/meals/dinner")),
      },
      {
        exact: "true",
        path: "/calendar",
        element: lazy(() =>
          import("./pages/postSignup/calendar/eventCalendar")
        ),
      },
      {
        exact: "true",
        path: "/groceries",
        element: lazy(() => import("./pages/postSignup/groceries")),
      },
      {
        exact: "true",
        path: "/signout",
        element: lazy(() => import("./pages/postSignup/signout")),
      },
      {
        exact: "true",
        path: "/cookbook",
        element: lazy(() => import("./views/cookBook/CookBook")),
      },
      {
        exact: "true",
        path: "/recipeDetails/:id",
        element: lazy(() => import("./views/cookBook/RecipeDetailedView")),
      },
      // {
      //   exact: 'true',
      //   path: '/basic/pagination',
      //   element: lazy(() => import('./views/ui-elements/basic/BasicPagination'))
      // },
      // {
      //   exact: 'true',
      //   path: '/basic/collapse',
      //   element: lazy(() => import('./views/ui-elements/basic/BasicCollapse'))
      // },
      // {
      //   exact: 'true',
      //   path: '/basic/tabs-pills',
      //   element: lazy(() => import('./views/ui-elements/basic/BasicTabsPills'))
      // },
      // {
      //   exact: 'true',
      //   path: '/basic/typography',
      //   element: lazy(() => import('./views/ui-elements/basic/BasicTypography'))
      // },
      // {
      //   exact: 'true',
      //   path: '/forms/form-basic',
      //   element: lazy(() => import('./views/forms/FormsElements'))
      // },
      // {
      //   exact: 'true',
      //   path: '/tables/bootstrap',
      //   element: lazy(() => import('./views/tables/BootstrapTable'))
      // },
      {
        exact: "true",
        path: "/goals",
        element: lazy(() => import("./views/charts")),
      },
      // {
      //   exact: 'true',
      //   path: '/maps/google-map',
      //   element: lazy(() => import('./views/maps/GoogleMaps'))
      // },
      // {
      //   exact: 'true',
      //   path: '/sample-page',
      //   element: lazy(() => import('./views/extra/SamplePage'))
      // },
      // {
      //   path: "*",
      //   exact: "true",
      //   element: () => <Navigate to={BASE_URL} />,
      // },
    ],
  },
];

export default routes;

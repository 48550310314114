import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, Bounce } from "react-toastify";
import "./index.scss";
import App from "./App";
import { AuthProvider } from "./contexts/authContext";
import { MealProvider } from "./contexts/mealContext";
import { Provider } from "react-redux";
import { ConfigProvider } from "./contexts/ConfigContext";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import store from "./store";
import { FilteredMealProvider } from "./contexts/mealByDaysContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
console.log("process.env", process.env);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <FilteredMealProvider>
          <MealProvider>
            <ConfigProvider>
              <Router>
                <ToastContainer
                  position="top-center"
                  autoClose={2000}
                  hideProgressBar={true}
                  newestOnTop={true}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                  transition={Bounce}
                />
                <App />
              </Router>
            </ConfigProvider>
          </MealProvider>
        </FilteredMealProvider>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
reportWebVitals();

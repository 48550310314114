import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Link } from "react-scroll";
import AuthContext from "../../contexts/authContext";
import eventEmitter from "../../event/event";
import SignInModalComponent from "../../utils/components/confirmationModal/confirmationModal";
import Navigation from "./navigation";
const Nav = styled.nav`
  .navbar {
    background: transparent !important;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0;
  }
  .navbar-brand {
    color: #333;
    &:hover {
      color: #333;
    }
  }
  .navbar-toggler {
    border: none;
    &:focus {
      outline: none;
    }
  }
  .navbar-brand-ai {
    color: #f9f9f9;
  }
`;

const NavbarCollapse = styled.div`
  @media (max-width: 900px) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [navbarBackground, setNavbarBackground] =
    useState("navbar-transparent");
  const [userName, setUserName] = useState("");
  const toggle = () => setIsOpen(!isOpen);

  const { auth, userID, username, setAuth, setUserID, setUsername } =
    useContext(AuthContext);

  // Set User Name for navbar
  useEffect(() => {
    setUserName(username);
  }, []);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setNavbarBackground("navbar-colored");
    } else {
      if (window.innerWidth <= 991) {
        setNavbarBackground("navbar-colored");
      } else {
        setNavbarBackground("navbar-transparent");
      }
    }
  };
  useEffect(() => {
    // Attach scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    // Cleanup: Remove scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once (on mount)
  useEffect(() => {
    // Check screen width on initial load
    const checkScreenSize = () => {
      if (window.innerWidth <= 991) {
        // Adjust this breakpoint as needed
        setNavbarBackground("navbar-colored");
      } else {
        handleScroll(); // Invoke handleScroll to set initial state for desktop/laptop
      }
    };

    checkScreenSize(); // Check on initial load

    // Attach resize event listener for responsive behavior
    window.addEventListener("resize", checkScreenSize);

    // Cleanup: Remove resize event listener when component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once (on mount)
  eventEmitter.on("signin-modal", (message) => {
    setShowModal(message);
  });
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {showModal ? <SignInModalComponent ShowBoolean={showModal} /> : ""}
      <Nav
        className={`navbar navbar-expand-lg ftco-navbar-light navbar-scroll ${navbarBackground}`}
      >
        <div className="container-xl">
          <NavLink
            className="navbar-brand align-items-center scroll-link"
            to="/"
            smooth={true}
            duration={500}
          >
            SIAA<span className="navbar-brand-ai">AI</span>
          </NavLink>

          <button className="navbar-toggler" type="button" onClick={toggle}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active scroll-link"
                  to="home"
                  smooth={true}
                  duration={500}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link scroll-link"
                  to="about-us"
                  smooth={true}
                  duration={500}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link scroll-link"
                  to="contact"
                  smooth={true}
                  duration={500}
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link scroll-link"
                  activeClassName="active"
                  to={auth ? "" : "signin"}
                  // onClick={() => {
                  //   // setShowModal(true);
                  // }}
                >
                  {auth ? <Navigation username={userName} /> : "Login"}
                </NavLink>
              </li>
              {/* `Welcome ${userName}!` */}
              {auth ? (
                ""
              ) : (
                <li className="nav-item ">
                  <NavLink
                    className="nav-link navbar-GetStartedBtn"
                    activeClassName="active"
                    to={"/signup"}
                  >
                    <span className=""> Get Started</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Nav>
    </>
  );
};

export default Navbar;

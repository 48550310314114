import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

// import NavLeft from './NavLeft';
import NavRight from "./NavRight";

import { ConfigContext } from "../../../contexts/ConfigContext";
import * as actionType from "../../../store/actions";

const NavBar = () => {
  const [moreToggle, setMoreToggle] = useState(false);
  const configContext = useContext(ConfigContext);
  const { collapseMenu, headerFixedLayout, layout } = configContext.state;
  const { dispatch } = configContext;

  let headerClass = ["navbar", "pcoded-header", "navbar-expand-lg"];
  if (headerFixedLayout && layout === "vertical") {
    headerClass = [...headerClass, "headerpos-fixed"];
  }

  let toggleClass = ["mobile-menu"];
  if (collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  const navToggleHandler = () => {
    dispatch({ type: actionType.COLLAPSE_MENU });
  };

  let moreClass = ["mob-toggler"];

  let collapseClass = ["collapse navbar-collapse"];
  if (moreToggle) {
    moreClass = [...moreClass, "on"];
    collapseClass = [...collapseClass, "show"];
  }

  let navBar = (
    <React.Fragment>
      <div className="m-header">
        <Link
          to="#"
          className={toggleClass.join(" ")}
          id="mobile-collapse"
          onClick={navToggleHandler}
        >
          <span />
        </Link>
        <Link to="#" className="b-brand">
          <div className="b-bg-mobile">
            <i className="feather icon-activity" />{" "}
          </div>
          <span className="b-title-mobile">SIAA</span>
        </Link>
        <Link
          to="#"
          className={moreClass.join(" ")}
          onClick={() => setMoreToggle(!moreToggle)}
        >
          <i className="feather icon-more-vertical" />
        </Link>
      </div>
      <div
        style={{ justifyContent: "flex-end" }}
        className={collapseClass.join(" ")}
      >
        {/* <NavLeft /> */}
        <NavRight />
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <header className={headerClass.join(" ")}>{navBar}</header>
    </React.Fragment>
  );
};

export default NavBar;

const menuItems = {
  items: [
    {
      id: "navigation",
      title: "",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "landing-page",
          title: "Landing Page",
          type: "item",
          icon: "feather icon-arrow-left",
          url: "/",
        },
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          icon: "feather icon-home",
          url: "/user-dashboard",
        },
        {
          id: "goals",
          title: "Goals",
          type: "item",
          icon: "feather icon-activity",
          url: "/goals",
        },
        {
          id: "breakfast",
          title: "Breakfast",
          type: "item",
          icon: "fa-solid fa-bread-slice",
          url: "/breakfast",
        },
        {
          id: "lunch",
          title: "Lunch",
          type: "item",
          icon: "fa-solid fa-utensils",
          url: "/lunch",
        },
        {
          id: "dinner",
          title: "Dinner",
          type: "item",
          icon: "fa-solid fa-drumstick-bite",
          url: "/dinner",
        },
        {
          id: "cookbook",
          title: "Cook Book",
          type: "item",
          icon: "fas fa-bookmark",
          url: "/cookbook",
        },
        {
          id: "calender",
          title: "Calendar",
          type: "item",
          icon: "fa-regular fa-calendar",
          url: "/calendar",
        },
        {
          id: "groceries",
          title: "Groceries",
          type: "item",
          icon: "fa-solid fa-cart-shopping",
          url: "/groceries",
        },
        {
          id: "signout",
          title: "Sign Out",
          type: "item",
          icon: "fa-solid fa-arrow-right-from-bracket",
          url: "/signout",
        },
      ],
    },
  ],
};

export default menuItems;

import { useState, useContext, createContext } from "react";

const FilteredMealContext = createContext();

export const useFilteredMeal = () => useContext(FilteredMealContext);

export const FilteredMealProvider = ({ children }) => {
  const [filteredBreakfast, setFilteredBreakfast] = useState();
  const [filteredLunch, setFilteredLunch] = useState();
  const [filteredDinner, setFilteredDinner] = useState();

  return (
    <FilteredMealContext.Provider
      value={{
        filteredBreakfast,
        filteredLunch,
        filteredDinner,
        setFilteredBreakfast,
        setFilteredLunch,
        setFilteredDinner,
      }}
    >
      {children}
    </FilteredMealContext.Provider>
  );
};

export default FilteredMealContext;

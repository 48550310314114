import React, { useContext, useEffect, useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "./contexts/authContext";
import { BrowserRouter, useLocation } from "react-router-dom";

// auth provider

import routes, { renderRoutes } from "./routes";
import MealContext from "./contexts/mealContext";
import eventEmitter from "./event/event";

const App = () => {
  const { auth, setAuth, setUserID, setUsername } = useContext(AuthContext);
  const { breakfast, setBreakfast, lunch, setLunch, dinner, setDinner } =
    useContext(MealContext);
  const { username, userID } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(
    currentDate.toISOString().split("T")[0]
  );
  eventEmitter.emit("filterByDate", selectedDate);
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    console.log("Retrieved token:", token);
    if (token) {
      console.log("YES TOKEN");
      verifyToken(token);
    } else {
      console.log("NO TOKEN");
      navigate("/");
    }
    // navigate("/user-dashboard");
    console.log("NO TOKEN");
  }, []);

  function verifyToken(token) {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/verifyToken`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("VERIFY RESPONSE");
        console.log("response====>", response.ok);
        if (response.ok) {
          console.log("if is working");
          return response.json();
        } else {
          console.log("else is working === >");
          return refreshAccessToken();
        }
      })
      .then((data) => {
        console.log("DATA:::::");
        console.log(data);
        if (data.is_valid) {
          setAuth(data.is_valid);
          setUsername(data.username);
          setUserID(data.userID);
          // navigate("/user-dashboard");
        } else {
          localStorage.removeItem(" ");
          setAuth(false);
          setUserID(null);
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error verifying or refreshing token:", error);
        setAuth(false);
        setUserID(null);
      });
  }
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      // Send the authorization code to your backend
      const fetchTokens = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/auth/callback?code= + ${code}`
          );
          if (response.ok) {
            console.log("Tokens retrieved and saved");
            // Optionally redirect to another page or show a success message
          } else {
            console.error("Error retrieving tokens");
          }
        } catch (error) {
          console.error("Error fetching tokens:", error);
        }
      };

      fetchTokens();
    }
  }, [location]);

  function refreshAccessToken() {
    // console.log("token is ----->", token);
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/refresh`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        console.log("access_token response:", response);
        if (!response.ok) {
          throw new Error("Failed to refresh token");
        }
        return response.json();
      })
      .then((data) => {
        console.log(
          "hello refreshAccessToken data is ===>",
          data.data.accessToken
        );
        localStorage.setItem("access_token", data.data.accessToken);
        return {
          is_valid: true,
          user: get_jwt_identity(data.data.accessToken),
        };
      })
      .catch((error) => {
        console.error("Refresh token failed", error);
        return { is_valid: false };
      });
  }

  function get_jwt_identity(token) {
    if (!token) {
      console.error("Token is undefined or null.");
      return null;
    }
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload).identity;
    } catch (e) {
      console.error("Failed to parse token:", e);
      return null;
    }
  }
  const post_options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the token in the Authorization header
      "Content-Type": "application/json",
    },
  };
  useEffect(() => {
    const fetchMealsAndEvents = async () => {
      try {
        const fetchBreakfast = await fetch(
          `${process.env.REACT_APP_API_URL}/api/meals/fetchBreakfast`,
          post_options
        );
        const breakfastData = await fetchBreakfast.json();
        let BreakfastMeals = breakfastData.data.result;
        const updatedBreakfast = BreakfastMeals.map((Breakfast) => ({
          mealType: "Breakfast",
          mealId: Breakfast?.mealId,
          mealName: Breakfast?.mealName,
          mealRecipe: Breakfast?.mealRecipe,
          mealIngredients: Breakfast?.mealIngredients,
          mealCalories: Breakfast?.mealCalories,
          mealProtein: Breakfast?.mealProtein,
          mealCarbs: Breakfast?.mealCarbs,
          mealfat: Breakfast?.mealFat,
          cookingTime: Breakfast?.mealCookingTimes,
          dateEaten: Breakfast?.dateEaten,
        }));

        setBreakfast(updatedBreakfast);

        const fetchLunch = await fetch(
          `${process.env.REACT_APP_API_URL}/api/meals/fetchLunch`,
          post_options
        );
        const lunchData = await fetchLunch.json();
        let LunchMeals = lunchData.data.result;
        const updatedLunch = LunchMeals.map((Lunch) => ({
          mealType: "Lunch",
          mealId: Lunch?.mealId,
          mealName: Lunch?.mealName,
          mealRecipe: Lunch?.mealRecipe,
          mealIngredients: Lunch?.mealIngredients,
          mealCalories: Lunch?.mealCalories,
          mealProtein: Lunch?.mealProtein,
          mealCarbs: Lunch?.mealCarbs,
          mealfat: Lunch?.mealFat,
          cookingTime: Lunch?.mealCookingTimes,
          dateEaten: Lunch?.dateEaten,
        }));
        setLunch(updatedLunch);

        const fetchDinner = await fetch(
          `${process.env.REACT_APP_API_URL}/api/meals/fetchDinner`,
          post_options
        );
        const dinnerData = await fetchDinner.json();
        let DinnerMeals = dinnerData.data.result;
        const updatedDinner = DinnerMeals.map((Dinner) => ({
          mealType: "Dinner",
          mealId: Dinner?.mealId,
          mealName: Dinner?.mealName,
          mealRecipe: Dinner?.mealRecipe,
          mealIngredients: Dinner?.mealIngredients,
          mealCalories: Dinner?.mealCalories,
          mealProtein: Dinner?.mealProtein,
          mealCarbs: Dinner?.mealCarbs,
          mealfat: Dinner?.mealFat,
          cookingTime: Dinner?.mealCookingTimes,
          dateEaten: Dinner?.dateEaten,
        }));
        setDinner(updatedDinner);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchMealsAndEvents();
  }, [username]);

  return (
    <React.Fragment>
      {/* <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}> */}

      {renderRoutes(routes)}
      {/* </BrowserRouter> */}
    </React.Fragment>
  );
};

export default App;

// import Home from "./pages/preSignup/home/home";
// import About from "./pages/preSignup/about/about";
// import AnnualReport from "./pages/preSignup/annual";
// import SignUp from "./pages/preSignup/signup";
// import SignIn from "./pages/preSignup/signin";
// import Dashboard from "./pages/postSignup/dashboard/dashboard";
// import Nutrition from "./pages/postSignup/nutrition";
// import Wellness from "./pages/postSignup/wellness";
// import Fitness from "./pages/postSignup/fitness";
// import SignOut from "./pages/postSignup/signout";
// import Breakfast from "./pages/postSignup/meals/breakfast";
// import Lunch from "./pages/postSignup/meals/lunch";
// import Dinner from "./pages/postSignup/meals/dinner";
// import Calendar from "./pages/postSignup/calendar";
// import Groceries from "./pages/postSignup/groceries";

// // function App() {
// const { auth, setAuth, setUserID, setUsername } = useContext(AuthContext);
// const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem("access_token");
//     console.log("Retrieved token:", token);
//     if (token) {
//       console.log("YES TOKEN");
//       verifyToken(token);
//     }
//     console.log("NO TOKEN");
//   }, []);

//   function verifyToken(token) {
//     fetch("/api/verifyToken", {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => {
//         console.log("VERIF RESPONSE");
//         console.log(response);
//         if (response.ok) {
//           return response.json();
//         } else {
//           return refreshAccessToken();
//         }
//       })
//       .then((data) => {
//         console.log("DATA:::::");
//         console.log(data);
//         if (data.is_valid) {
//           setAuth(true);
//           setUserID(data.userID);
//           setUsername(data.username);
//           navigate("/user-dashboard");
//         } else {
//           localStorage.removeItem("token");
//           setAuth(false);
//           setUserID(null);
//         }
//       })
//       .catch((error) => {
//         console.error("Error verifying or refreshing token:", error);
//         setAuth(false);
//         setUserID(null);
//       });
//   }

//   function refreshAccessToken() {
//     return fetch("/api/refresh", {
//       method: "POST",
//       credentials: "include",
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Failed to refresh token");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         localStorage.setItem("token", data.accessToken);
//         return { is_valid: true, user: get_jwt_identity(data.accessToken) };
//       })
//       .catch((error) => {
//         console.error("Refresh token failed", error);
//         return { is_valid: false };
//       });
//   }

//   function get_jwt_identity(token) {
//     if (!token) {
//       console.error("Token is undefined or null.");
//       return null;
//     }
//     try {
//       const base64Url = token.split(".")[1];
//       const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//       const jsonPayload = decodeURIComponent(
//         atob(base64)
//           .split("")
//           .map(function (c) {
//             return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
//           })
//           .join("")
//       );
//       return JSON.parse(jsonPayload).identity;
//     } catch (e) {
//       console.error("Failed to parse token:", e);
//       return null;
//     }
//   }

//   return (
//     <div>
//       {!auth && <Navbar />}
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/annual" element={<AnnualReport />} />
//         <Route path="/signup" element={<SignUp />} />
//         <Route path="/signin" element={<SignIn />} />
//         <Route path="/user-dashboard" element={<Dashboard />} />
//         <Route path="/nutrition" element={<Nutrition />} />
//         <Route path="/wellness" element={<Wellness />} />
//         <Route path="/fitness" element={<Fitness />} />
//         <Route path="/signout" element={<SignOut />} />
//         <Route path="/breakfast" element={<Breakfast />} />
//         <Route path="/lunch" element={<Lunch />} />
//         <Route path="/dinner" element={<Dinner />} />
//         <Route path="/calendar" element={<Calendar />} />
//         <Route path="/groceries" element={<Groceries />} />
//       </Routes>
//     </div>
//   );
// }

// export default App;

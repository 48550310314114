import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import eventEmitter from "../../../event/event";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/authContext";
import MoonLoader from "react-spinners/MoonLoader";
import setCookie from "../../functions/setCookies";
import Navbar from "../../../components/Navbar/Navbar";
import { toast } from "react-toastify";
import { Button, ButtonGroup, CloseButton, Col, Form } from "react-bootstrap";

const override = {
  position: "absolute",
  top: "47%",
  left: "47%",
  fontSize: 16,
  display: "block",
  margin: "0 auto",
  borderColor: "#198754",
};
function ConfirmationModal(props) {
  const [show, setShow] = useState(false);
  const { ShowBoolean, recipeId } = props;
  const [recipeID, setRecipeID] = useState();
  useEffect(() => {
    setShow(ShowBoolean);
  }, [ShowBoolean]);
  console.log("show====>", show);

  useEffect(() => {
    const handleDeleteConfirmation = (id) => {
      console.log("Message received in confirmation modal:", id);
      setRecipeID(id);
    };

    eventEmitter.on("deleteRecipeConfirmations", handleDeleteConfirmation);

    // Clean up event listener on component unmount
    return () => {
      eventEmitter.off("deleteRecipeConfirmations", handleDeleteConfirmation);
    };
  }, []);
  // To observe changes in recipeID
  useEffect(() => {
    console.log("recipeID====>", recipeID);
  }, [recipeID]);
  const navigate = useNavigate();

  // Function to handle recipe deletion
  const handleDeleteRecipe = async (id) => {
    console.log("recipeIdhandleDeleteRecipe====>", id);

    if (!id) {
      toast.error("No recipe selected");
      return;
    }

    const payload = {
      recipeId: id,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/meals/deleteSaveRecipes`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const deletedRecipe = await response.json();
      console.log("saveRecipes======>", deletedRecipe);

      toast.info("Recipe Deleted");
      setShow(false);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting the recipe:", error);
      toast.error("Failed to delete the recipe");
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          eventEmitter.emit("signin-modal", false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="text-center"
            style={{ fontWeight: "bold" }}
          >
            Delete Recipe Confirmation
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <Form className="container-signin"> */}

          <Modal.Body className="text-center" style={{ fontWeight: "bold" }}>
            Are you sure you want to remove this recipe from your saved list?
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
                eventEmitter.emit("signin-modal", false);
              }}
            >
              Cancel
            </Button>{" "}
            <Button
              type="submit"
              variant="success"
              onClick={() => handleDeleteRecipe(recipeId)}
            >
              Yes
            </Button>
          </Modal.Footer>

          {/* </Form> */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConfirmationModal;

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./navigation.scss";

function Navigation(props) {
  const { username } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <li
      className="dropdown"
      onMouseEnter={toggleDropdown}
      onMouseLeave={toggleDropdown}
    >
      <span className="dropbtn">Welcome {username}!</span>
      <ul className={`dropdown-content ${dropdownOpen ? "show" : ""} `}>
        <li>
          <NavLink className="" activeClassName="active" to="/user-dashboard">
            User Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to="signout" activeClassName="activeSignout">
            Sign Out
          </NavLink>
        </li>
        {/* Add more dropdown items here */}
      </ul>
    </li>
  );
}

export default Navigation;

import React from 'react';
import { Nav } from 'react-bootstrap';

const NavRight = () => {
  return (
    <React.Fragment>
      <Nav as="ul" variant="underline" className="navbar-nav ml-auto justify-content-end" id="navbar-center">
        <Nav.Item as="li" bsPrefix=" ">
          <Nav.Link href="/check-in">{/* <i className="icon feather icon-mail" /> */}Check-in</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" bsPrefix=" ">
          <Nav.Link href="/goals">{/* <i className="icon feather icon-mail" /> */}Goals</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" bsPrefix=" ">
          <Nav.Link href="/reports">{/* <i className="icon feather icon-mail" /> */}Reports</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" bsPrefix=" ">
          <Nav.Link href="/my-profile">{/* <i className="icon feather icon-mail" /> */}My Profile</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" bsPrefix=" ">
          <Nav.Link href="/siaa-chat">SIAA Chat</Nav.Link>
        </Nav.Item>
      </Nav>
      {/* <ChatList listOpen={listOpen} closed={() => setListOpen(false)} /> */}
    </React.Fragment>
  );
};

export default NavRight;

import { useState, useContext, createContext } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [auth, setAuth] = useState(false);
  const [userID, setUserID] = useState('');
  
  return (
    <AuthContext.Provider 
      value={{
        username,
        auth,
        userID,
        setUsername,
        setUserID,
        setAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;